$small: 576px;
$medium: 768px;
$large: 992px;
$extraLarge: 1200px;

.about-wrapper {
	min-height: 100vh;
	position: relative;
}
.home-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.about-text {
	position: absolute;
	top: calc(50% - 60px);
	font-weight: 400;
	font-size: 40px;
	max-width: 90%;

	@media screen and (max-width: $extraLarge) {
		font-size: 32px;
		top: calc(50% - 100px);
	}

	@media screen and (max-width: $medium) {
		font-size: 24px;
		max-width: 95%;
	}
	@media screen and (max-width: $small) {
		max-width: 100%;
	}
}

.about-span {
	margin: 0 10px;
	position: relative;
	white-space: nowrap;

	@media screen and (max-width: $extraLarge) {
		margin: 0 8px;
	}

	@media screen and (max-width: $medium) {
		margin: 0 6px;
	}
	@media screen and (max-width: $small) {
		margin: 0 6px;
	}

	&:after {
		border-top: 2px solid #000;
		position: absolute;
		content: "";
		right: 0;
		top: 57%;
		left: 0;
	}
}

.about-img {
	width: 40px;
	height: 40px;
	margin-left: 10px;

	@media screen and (max-width: $medium) {
		width: 30px;
		height: 25px;
	}
}
