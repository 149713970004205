$small: 576px;
$medium: 768px;
$large: 992px;
$extraLarge: 1200px;

.details-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.details-container {
	flex: 1 0 auto;
}

.details-block {
	margin-bottom: 25px;

	@media screen and (max-width: $medium) {
		margin-bottom: 15px;
	}
}

.mb-large {
	margin-bottom: 90px;

	@media screen and (max-width: $large) {
		margin-bottom: 50px;
	}

	@media screen and (max-width: $small) {
		margin-bottom: 30px;
	}
}

.mb-medium {
	margin-bottom: 23px;

	@media screen and (max-width: $small) {
		margin-bottom: 15px;
	}
}

.line-height-0 {
	line-height: 0;
}

.mob-pt-40 {
	@media screen and (max-width: $small) {
		padding-top: 40px !important;
	}
}

.details-img {
	width: 100%;
	background-size: cover;

	&__multy {
		width: 49%;
		background-size: cover;

		@media screen and (max-width: $medium) {
			width: 100%;
		}
	}
}

.details-section-img {
	min-height: 400px;

	@media screen and (max-width: $large) {
		min-height: 300px;
	}

	@media screen and (max-width: $medium) {
		min-height: 150px;
	}
}

.details-section {
	padding: 0 50px;

	@media screen and (max-width: $large) {
		padding: 0 25px;
	}

	@media screen and (max-width: $medium) {
		padding: 0 15px;
	}
	&__white {
		background-color: #fff;
	}
	&__black {
		background-color: rgba(0, 0, 0, 1);
		color: #fff;
		margin-bottom: 50px;

		@media screen and (max-width: $medium) {
			margin-bottom: 25px;
		}
	}
	&__multyImg {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

.details-description {
	display: flex;
	flex-wrap: wrap;
	padding: 100px 0 0px 0;

	@media screen and (max-width: $large) {
		padding: 50px 0 20px 0;
	}

	&__item {
		width: 50%;
		margin-bottom: 100px;

		@media screen and (max-width: $large) {
			width: 100%;
			margin-bottom: 30px;
		}
	}

	&__title {
		font-size: 18px;
		font-weight: 400;
		line-height: 28px;
		color: #858688;
		text-transform: uppercase;
		margin-bottom: 10px;

		@media screen and (max-width: $medium) {
			font-size: 16px;
		}
	}

	&__text {
		font-size: 18px;
		font-weight: 400;
		line-height: 28px;
		padding-right: 15%;

		@media screen and (max-width: $medium) {
			font-size: 16px;
		}
	}
}

.details-stages {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 65px;

	@media screen and (max-width: $large) {
		justify-content: flex-start;
		margin-bottom: 25px;
	}

	@media screen and (max-width: $small) {
		margin-bottom: 15px;
	}

	&__inner {
		width: 50%;

		@media screen and (max-width: $large) {
			width: 80%;
		}

		@media screen and (max-width: $medium) {
			width: 100%;
		}
	}

	&__item {
		padding-right: 15%;
		margin-bottom: 25px;

		@media screen and (max-width: $medium) {
			padding-right: 0%;
		}

		@media screen and (max-width: $small) {
			margin-bottom: 15px;
		}
	}

	&__text {
		font-size: 18px;
		font-weight: 400;
		line-height: 24px;
		color: #000000;

		@media screen and (max-width: $medium) {
			font-size: 16px;
		}

		&--bold {
			font-family: "HerosBold";
			font-weight: 700;
			font-size: 18px;

			@media screen and (max-width: $medium) {
				font-size: 16px;
			}
		}
	}

	&__span {
		font-size: 18px;
		font-weight: 400;
		line-height: 24px;
		color: #000000;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 30px;

		@media screen and (max-width: $medium) {
			font-size: 16px;
			margin-top: 15px;
		}

		&--img {
			width: 20px;
			height: 20px;
			margin-right: 5px;
		}

		&--link {
			font-size: 18px;
			font-weight: 400;
			line-height: 24px;
			color: #000000;
			text-decoration: underline;
			margin-left: 5px;

			@media screen and (max-width: $medium) {
				font-size: 16px;
			}
		}
	}
}

.details-btn-next {
	background-color: rgba(0, 0, 0, 1);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
	transition: all 0.35s;
	z-index: 1;
	margin-bottom: 30px;

	&:before,
	&:after {
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		top: -100%;
		left: 0;
		background: rgb(245, 121, 219);
		z-index: -1;
		transition: all 0.35s;
	}

	&:before {
		opacity: 0.5;
	}

	&:after {
		transition-delay: 0.2s;
	}

	&:hover:before,
	&:hover:after {
		top: 0;
	}

	&--text {
		color: rgba(255, 255, 255, 1);
		font-weight: 400;
		font-size: 48px;
		text-align: center;
		padding: 80px 0;

		@media screen and (max-width: $large) {
			padding: 65px 0;
		}

		@media screen and (max-width: $medium) {
			padding: 40px 0;
			font-size: 28px;
		}

		@media screen and (max-width: $small) {
			padding: 28px 0;
			font-size: 20px;
		}
	}

	&--img {
		width: 50px;
		height: 40px;
		margin-left: 15px;
		margin-top: 7px;

		@media screen and (max-width: $medium) {
			width: 25px;
			height: 20px;
			margin-left: 10px;
			margin-top: 4px;
		}

		@media screen and (max-width: $small) {
			width: 20px;
			height: 15px;
			margin-left: 8px;
			margin-top: 3px;
		}
	}
}
