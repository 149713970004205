$small: 576px;
$medium: 768px;
$large: 992px;
$extraLarge: 1200px;

.header {
	display: flex;
	justify-content: space-between;
	padding: 30px 50px;
	background-color: transparent;
	position: fixed;
	width: calc(100% - 100px);
	z-index: 1;

	@media screen and (max-width: $large) {
		padding: 25px;
		width: calc(100% - 50px);
	}

	@media screen and (max-width: $medium) {
		padding: 15px;
		width: calc(100% - 30px);
	}

	&--transparent {
		// position: absolute;
		// width: calc(100% - 100px);
		// @media screen and (max-width: $large) {
		// 	width: calc(100% - 50px);
		// }

		// @media screen and (max-width: $medium) {
		// 	width: calc(100% - 30px);
		// }
	}
}

.header-logo {
	width: 49px;
	height: 39px;

	@media screen and (max-width: $medium) {
		width: 38px;
		height: 30px;
	}
}

.header-link {
	font-weight: 400;
	font-size: 20px;
	color: #000000;

	@media screen and (max-width: $medium) {
		font-size: 16px;
	}

	&:hover {
		color: rgb(245, 121, 219);
		transition: all 0.5s;
	}
}
