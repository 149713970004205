$small: 576px;
$medium: 768px;
$large: 992px;
$extraLarge: 1200px;

.list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
