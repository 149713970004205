$small: 576px;
$medium: 768px;
$large: 992px;
$extraLarge: 1200px;

.container {
	flex: 1 0 auto;
	margin: 0 50px;

	@media screen and (max-width: $large) {
		margin: 0 25px;
	}

	@media screen and (max-width: $medium) {
		margin: 0 15px;
	}
}

.container-inner {
	background-color: rgba(0, 0, 0, 1);
}

.container-black {
	background-color: rgba(0, 0, 0, 1);
}
